.qa-service .question-container li{
    
    /* background-color: rgba(96, 93, 186, 0.3); */
    list-style-type: none;
    margin-bottom: 10px;
       /* float: right; */
       padding: 5px 10px;
       border-radius: 10px;
       /* display: inline-block; */
       
   }

   .img-container-100 img{
    width: 100%;
    height: 80vh;
   }




    .qa-card-even:nth-child(even){
    background-color: #fef3e4;
   }
   .qa-card-even:nth-child(odd){
    background-color: #eaf3ef;
   }
   .qa-card-odd:nth-child(odd){
    background-color: #fae6e7;
   }
   .qa-card-odd:nth-child(even){
    background-color: #e0e9fa;
   } 
   
   .qa-card{
   
    padding: 10px;
    
   }
   .qa-service .qa-card > div{
    padding: 18px 24px;
    border-radius: 33px 0 33px 15px;
   }
   .green{
    background-color: #eaf3ef
   }
   .blue{
    background-color: #e0e9fa
   }
   .pink{
    background-color: #fae6e7
   }
   .yellow{
    background-color: #fef3e4
   }
/* ---------------------------- */
   .qa-headers a{
    position: relative;
text-decoration: none;
color: #FFFF99;
   }

   .qa-headers a .header-link::after {
    content: '';
    position: absolute;
    width: 60%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 28%;
    background-color: #ffff00;
    transform-origin: bottom right;
    transition: transform 0.6s ease-out;
  }
  
  .qa-headers a:hover .header-link::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  } 

   .header-ind .bubble{
    display: inline-block;
    
    border: 1px solid yellow;
    padding: 12%;
   }
   .header-ind .bubble svg{
    width: 92%;
    height: 92%;
    max-width: 40px;
    max-height: 40px;
    fill: yellow;
   }
   .qa-headers a:hover .header-ind .bubble{
    background: radial-gradient(#108d16, transparent);
   }
   .header-ind .ind{
    color: #000;
    position: absolute;
    font-size: 28px;
    top: 0;
    left: 27%;
    
   }
   .header-ind{
    display: inline-block;
    position: relative;
   }
   .text-right .row{
    justify-content: end;
   }
   .text-left .row{
    justify-content: center;
    /* flex-direction: row-reverse; */
    align-items: center;
   }
   .question-text{
    /* border-right: 1px solid;
    text-align: right; */
    color: #969696;
    /* padding-right: 30px; */
   }
   .text-left .question-icon{
    /* text-align: center; */
   }
  
   .questions-container{
    position: relative;
   }
   .quesion-mark-container{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 4%;
    right: 0;
    z-index: 999;

    transition: transform 0.6s;
    transform-style: preserve-3d;
  
   }
   .answer{
    position: absolute;
    
    text-align: center;
    border:1px solid#FFD700;
   
    border-radius: 50%;
    padding-top: 7%;
    width: 200px;
    height: 200px;
   }
  
   /* .quesion-mark-container:hover + .answer{
    
   
    width: 200px; height: 200px; display: block;
   }
   .quesion-mark-container:hover .svg{
    display: none;
   } */

  .grey-bg{
    /* background: #323232; */
    /* background: linear-gradient(90deg, #434167 , #434167, #f34566); */
  } 
  .solution-text{
    /* color: #8cff66; */
    color: #5cb43f;
    align-items: start;
  }
  .solution-text svg{
    /* margin-bottom: 2rem; */
    width: 40px;
    fill: #53e766;
    margin-bottom: 1rem;
  }
  @media screen and (min-width:768px){
    .solution-text{
      align-items: center;
    }
    .solution-text svg{
      width: 66px;
    }
  }
  .answer{
    transform: rotateY(180deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
   
  }
  .quesion-mark-container svg{
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
 
  .quesion-mark-container:hover {
    transform: rotateY(180deg);
  }
  .qa2-bg{
    background-image: url('./../../assets/svgs/QA/target.svg');
  }
 
  @keyframes showAns {
    from {width: 0; height: 0; display: none;}
    to {width: 200px; height: 200px; display: block;}
  }

  .service-overlay{
    background-image: url('./../../assets/images/bg-overlay.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 10% 10%;
    animation: serviceOverlay 2s ;
}


@keyframes serviceOverlay {
  from{background-position: -50% -50%;}
  to{background-position: 10% 10%;}
}

.solution-points-container{
  /* align-items: end; */
}
.solution-points-container .col-md-4.mt-3{
  display: flex;
}
.solution-points .points-text{
  padding-bottom: 40px;
  color: #8c8c8c;
}
.solution-points{
  position: relative;
  padding: 6% 9% 9% 6%;
  background: linear-gradient(45deg, black, transparent);
  border-radius: 10px;
  width:100%;
}
.solution-points h3{
  margin-bottom: 10px;
}
.solution-points svg{
  width: 50px;
    height: 50px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    fill: #292d32;

}
.qa-headers .row{
  align-items: center;
}
.qa-services-list .light-bg{
  background-color:#05034a
}
.qa-services-list .dark-bg, .qa-services-list .light-bg{
  
}
.solution-points svg .linesandangles_een,
.solution-points svg #add, .solution-points svg #ic_fluent_bug_report_24_filled,
.solution-points svg .accessibility-icon path, .solution-points svg .accessibility-icon polygon,
.solution-points svg #Page-1 #icon{
  fill: #292d32;
}
.qa-services-list .qa-headers a .row{
  align-items: start;
}
.qa-services-list .qa-headers a .row .header-ind{
  margin-top: 8px;
}


 
   
   