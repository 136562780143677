.blog-container h3{
    padding: 10px 0;
    text-align: center;
}
.blog-container .img-container-100p{
    padding-top: 25px;
    border: 4px solid #FFF;
}
.blog-container a{
    text-decoration: none;
    color: #FFF;
}
.blog-container .img-container-100p img{
    transition: all 0.3s;
}
.blog-container .img-container-100p:hover img{
    transform: scale(1.05,1.05); 
}

.blog-container .img-container-100p .clock{
    transition: all 0.3s;
    background: linear-gradient(45deg, #b9afaf, black);
    height: 130px;
    width: 130px;
}
.blog-container .img-container-100p:hover .clock{
    transform: scale(1.05,1.05); 
}
.clock-logo{
    width: 45px;
    bottom: 10%;
    position: absolute;
    left: 34%;
}
.blog-container .img-container-100p svg{
height: 130px;
width: 130px;
margin: auto;
display: block;
margin-bottom: 44px;
margin-top: 20px;
transition: all 0.3s;
}
.blog-container .img-container-100p:hover svg{
    transform: scale(1.05,1.05); 
}