.accordion-button, .accordion-item{
    background-color: transparent !important;
    /* border: 0 !important; */
    color: inherit !important;
    outline: 0 !important;
}
button:focus:not(:focus-visible) {
    outline: 0 !important;
}

 .accordion-header button:focus,  .accordion-header button, .accordion-header, .accordion-header:focus{
    outline: none !important;
    border: 0 !important;
 }

 .accordion-button:after, .accordion-button:before{
 position: absolute;
    content: '';
    height: 2px;
    width: 14px;
    right: 7px;
    background-color: #FFF;
    transition: all .3s ease-in-out;
 }

 .accordion-button:after{
    top: 50%;
    right: 7px;
    transform: translate(-50%, -50%);
    display: none;
    }
 
 .accordion-button.collapsed:after {
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    display: block;
}
 .accordion-button.collapsed:before {
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%) rotate(-90deg);
} 


