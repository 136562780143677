.about-us-container{
    padding: 5%;
}
.about-img-container{
    position: relative;
}
.about-img-container img{
    max-width: 100%;
}

.wheel{
    position: absolute;
    width: 25%;
    top: 5%;
    right: -10px;
    background: #02012d;
    border-radius: 50%;
    transform: rotate(0deg);
    animation: wheelRotate 6s infinite linear;
}
@keyframes wheelRotate {
    from{transform: rotate(360deg)}
    to{transform: rotate(0deg)}
}
.like{
    position: absolute;
    width: 43px;
    top: 0%;
    right: 35%;
}
.like.heart{
    width: 20px;
    top: 9px;
    right: 37%;
    transform: scale(1);
    animation: heartGrow 1.5s infinite 0.5s ease-in;
}
@keyframes heartGrow {
    from{transform: scale(0.2);}
    to{transform: scale(1);}
}
.web-dev-text{
    position: absolute;
    top: 42%;
    left: 30%;
}
.about-marketing{
    position: absolute;
    width: 60px;
    bottom: 26%;
    right: 37%;
    transform: rotateY(0deg);
    animation: marketingFlip 3s ease-in 1s infinite;
}
@keyframes marketingFlip {
    from{transform: rotateY(360deg);}
    to{transform:rotateY(0deg);}
}
.digimarketing-text{
    position: absolute;
    bottom: 18%;
    right: 27%;
    color: #67758b;
}
