/* @font-face {
  font-family: Playfair;
  src:url('./assets/fonts/Playfair_Display/static/PlayfairDisplay-Regular.ttf')

} */
/* @font-face {
  font-family: Roboto;
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf');
} */

@font-face {
  font-family: Baloo;
  /* font-display:auto; */
  /* src: url('./assets/fonts/Baumans/Baumans-Regular.ttf'); */
  /* src:url('./assets/fonts/Playfair_Display/static/PlayfairDisplay-Regular.ttf') */
  src:url('./assets/fonts/baloo-baina/baloo.bhaina-regular.ttf');

}
@font-face {
  font-family: Podkova;
  src: url('./assets/fonts/Podkova/static/Podkova-Regular.ttf');
  
}
@font-face {
  font-family: Nunito_Sans;
  src: url('./assets/fonts/Nunito_Sans/static/NunitoSans_10pt-Regular.ttf');
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: Nunito_Sans !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   background:#02012d !important;
   color: #FFF !important;
   font-size: 1.2rem !important;
}

 button{
  background-color: #FFFF99;
  color: #001F4D;
 }
 body a{
  color: #FFFF99 ;
 }

 .prime-btn svg{
  width: 80px;
  border:1px solid #FFFF99;
  border-radius: 50%;
  padding: 20px;
  background: rgba(243, 69, 102, 0);
  transition: all 1s;
  z-index: 9;
}
.prime-btn .link-text{
  color: #FFFF99;
  margin: 0 0 0 -10px;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1px;
  z-index: 99;
  transition: all 1s;
}
.prime-btn{
  text-decoration: none;
}
.prime-btn:hover svg{
  background: #FFFF99;
  transform: scale(1.05,1.05);
}
.prime-btn svg path{
  fill: #FFFF99;
}
.prime-btn:hover svg path{
  fill: #000;
}
.prime-btn:hover .link-text{
  margin: 0 0 0 10px;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* headers */
h1{
   font-size: 3.5rem !important; 
  text-transform: uppercase;
  margin-bottom: 1.3rem !important;
  font-family: Baloo;
}
h2,h3,h4,h5,h6{
  font-family: Podkova !important;
}
h2{
  font-size: 2.7rem !important; 
  /* text-transform: uppercase; */
  margin-bottom: 1rem !important;
  /* color:#ffcc99 !important; */
  color: #ef9031 !important;
}
h3{
  font-size: 1.8rem !important; 
}
h4{
  font-size: 1.5rem !important; 
  color: #a2c4c9 !important;
}
h5{
  font-size: 1.2rem !important; 
}
h6{
  font-size: 1rem !important; 
  letter-spacing: 1.8px;
  color: #ffcc99 !important;
}


/* fonts */
.fs-40{
  font-size: 2.5rem;
}
.fs-24{
  font-size: 1.5rem;
}
.fs-21{
  font-size: 1.25rem!important;
}
.fs-16{
  font-size: 1rem!important;
}

/* padding */
.p-tb-20{
  padding-top: 20px;
  padding-bottom:20px ;
}
.p-tb-40{
  padding-top:40px ;
  padding-bottom:40px ;
}
.p-t-60{
  padding-top:60px ;
}
.p-t-100{
  padding-top:100px ;
}

/* theme specific */

.theme-text{
  color: #969696;
}
.grey-text{
  color: #8c8c8c;
}
.blue-text{
  color:#6d6dff
}
.red-text{
  color: #f45e5e;
}
.theme-btn{
  background: linear-gradient(45deg, #4e43b8, #c0b2d3);
  font-size: 22px !important;
  color: #FFF !important;
}
.theme-btn-small{
  font-size: 19px !important;
}
.next-line{
  clear: both;
}
.align-center{
  align-items: center;
}
.align-end{
  align-items: end;
}
.align-self-start{
  align-self: flex-start;
}
.align-self-end{
  align-self: flex-end;
}
.subpage-header{
  padding: 6% 0;
  text-align: center;
}
.subpage-sub-header{
  /* padding: 0 26%; */
  font-size: 21px;
  color: #918f8f;
}

/* Animations */
.reveal{
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}
 .fixedNav{
  position: fixed !important;
    background: transparent;
    z-index: 999;
    padding: 0;
    display: block;
    width: auto;
    top: 20px;
    right: 20px;
    display: none;
} 
/* .fixedNav:hover .navbar-collapse{
  display: block;
} */
.fixedNav .hamburger-menu{
 background: #02012d;
 box-shadow: 1px 1px 4px 1px #036;
 
}
.fixedNav .navbar-collapse{
  background: #02012d;
    box-shadow: 1px 1px 4px 1px #e6f2ff;
    position: fixed;
    right: 22px;
 }
 .fixedNav .nav-item{
  padding-right: 20px;
  margin-left: 0;
    padding-left: 20px;
 }
.left-anim{
  transform: translateX(0);
  opacity: 1;
  transition: 1s all ease;
  animation: animLeft 1s ease-in;
}
.left-anim15{
  animation: animLeft 1.5s ease-in;
}
.left-anim2{
  animation: animLeft 2s ease-in;
}
@keyframes animLeft {
  from{transform: translateX(-150px);opacity: 0;}
  to{transform: translateX(0);opacity: 1;}
}
.img-container-100p img{
  max-width: 100%;
}
.blog-demo{
   transform: translateX(-50px); 
   /* right: 0;
   top: 0; */
  transition: 1s all ease;
 
}
/* .blog-demo.fix-blog-demo{
  position: fixed;
  top: 10px;
  right: 10px;
  transform: translateX(0) scale(0.5);
 
}
.blog-demo.fix-blog-demo.clock{
  background: linear-gradient(45deg, #9d8585, transparent);
} */

/* .light-bg-stroke{
  -webkit-text-stroke: 2px #c7d2df;
  color: transparent;
  font-size: 8rem;
  position: absolute;
  z-index: 1;
  text-transform: uppercase;
} */
.text-right{
  text-align: right;
}

.accordion-flush>.accordion-item{
  margin-top: 15px;
}
.accordion-button{
font-size: 1.2rem !important;
}
@media screen and (min-width:768px) {
  .container-fluid{
    padding-left: calc(6.5rem * 0.5) !important;
    padding-right: calc(6.5rem * 0.5) !important;
  }
}
#accordionFlushExample .accordion-body{
  font-size: 1rem;
  color: #8c8c8c;
}
