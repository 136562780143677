.thankyou-icon .bi{
    color: #2df22d;
    font-size: 70px;
}
.thankyou-icon{
    margin-bottom: 2rem;
}
.thankyou-page .grey-text{
    font-size: 14px;
}
.thankyou-page .contact-us-btn{
    width: 220px !important;
    padding: 14px 0;
    text-align: center;
    margin: auto;
    text-decoration: none;
}