.clock {
    position: relative;
    margin: auto;
    height: 200px;
    width: 200px;
    border: 1px solid #ffffff;
    border-radius: 50%;
    background-color: #FFF;
    background-image: url('./../../../assets/images/clock.png');
    background-size: cover;
}

.hour-hand,
.minute-hand,
.second-hand {
    position: absolute;
    background: black;
    border-radius: 10px;
    transform-origin: bottom;
}

.hour-hand {
    width: 1.8%;
    height: 25%;
    top: 25%;
    left: 48.85%;
    opacity: 0.8;
}

.minute-hand {
    width: 1.6%;
    height: 30%;
    top: 19%;
    left: 48.9%;
    opacity: 0.8;
}

.second-hand {
    width: 1%;
    height: 40%;
    top: 9%;
    left: 49.25%;
    opacity: 0.8;
    background: red;
}

.center-circle {
    width: 12px;
    height: 12px;
    background-color: #333;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}
.clock-logo img{
    max-width: 100%;
}
.analog-clock-blog-container .clock-logo{
    width: 50px;
    bottom: 18%;
    position: absolute;
    left: 38%;
}
.code-snnippet-container{
    position: relative;
    /* width: 70%; */
    height: 250px;
    overflow-y: auto;
    background: black;
    padding: 20px;
    line-height: 24px;
    color: #FFF;
    border: 1px solid #545252;
    border-radius: 5px;
}
.code-snippet{
    position: relative;
}
.copybtn{
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    color: #779933;
    z-index: 99;
    font-size: 12px;
}

/* beautify scrollbar */

.code-snnippet-container::-webkit-scrollbar {
    width: 5px;
}

.code-snnippet-container::-webkit-scrollbar-track {
    background-color: #779933;
    border-radius: 100px; /* Rounded edges for the track */
}

.code-snnippet-container::-webkit-scrollbar-thumb {
    background-color: #6153a4;
    border-radius: 100px; /* Rounded edges for the thumb */
}
.code-snnippet-container::-webkit-scrollbar-thumb:hover {
    background-color: #121652; /* Lighter color on hover */
}
.analog-clock-blog-container .subpage-header{
    padding: 6% 0 4% 0;
}
.justify-end{
    display: flex;
    justify-content: end;
}

