.approach-svg svg{
    width: 50px;
    height: 50px;
    fill: #918a8a;
}
.approach-svg{
    /* border: 1px solid #fff;
    border-radius: 50%;
    display: inline-block;
    padding: 15px;
    box-shadow: 1px 1px 1px #fff; */
    position: absolute;
    top:5px;
    right: 10px;
}
.qa-apr-box{
    display: flex;
    margin-bottom: 20px;
}
.approach-block{
   border: 1px solid #7c62e5; 
    position: relative;
    padding: 2rem;
    padding-bottom: 0.5rem;
    text-align: center;
    width: 100%;
}
.step-count{
 position: absolute;
 top: 0;
 left: 0;
 background: #7c62e5;
 display: inline-block;
 border-radius: 0 0px 50% 0px;
 padding: 5px 13px;
 /* z-index: 99; */
}
/* .step-count::after{
    content: " ";
    border: 0.2px dashed #7c62e5;
    width: 100%;
    display: block;
    position: absolute;
    left: 40px;
    top: 20px;
} */



.approach{
    color: #FFF;
    margin-top: 21px;
}
.arrow {
    /* border: solid #ffcc99; */
    border: solid #0e0a9f;
    border-width: 0 30px 30px 0px;
    display: inline-block;
    padding: 30px;
    /* background-color: #ffcc99; */
    background-color: #0e0a9f;
    margin-left: 10px;
    border-radius: 0px 0 6px 0;
    
  }
.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  .approach-text-container{
    position: absolute;
    top: -19px;
    left: 55px;
    /* background-color: #ffcc99; */
    background-color :#0e0a9f;
    height: 128px;
    padding: 12px 5px 20px 0;
    width: calc(100% - 50px);
    text-align: center;
    border-radius: 0 6px 6px 0;
  }
  .approach-text-container svg{
    width: 30px;
    height: 30px;
    position: absolute;
    /* right: 10px;
    top: 5px; */
    left: -42px;
    top: 45px;
    fill: #FFF;
  }
  .approach-text-container h4{
    /* color: black !important; */
    color: #a2c4c9 !important;
    font-weight: bold;
    letter-spacing: 0.06rem;
  }
  .qa-apr-box h4{
    color: #8cff66 !important;
    font-weight: bold;
    letter-spacing: 0.06rem;
  }
  .more-content{
    position: absolute;
    top: 0;
    background: #050352;
    width: 100%;
    left: 0;
    height: 100%;
    display: none;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
   
  }
  .more-content .closeMore{
    font-size: 12px;
    position: sticky;
    top: 0;
    right: 0;
    background: transparent;
    border: 0;
    color: #FFF;
    display: flex;
  }
  /* .more-content::-webkit-scrollbar {
    width: 4px;
  } */

  .has-more{
    width: 100%;
  }
  /* .has-more:hover .more-content{
display: block;
  } */
  .more-btn{
    letter-spacing: 2px;
    display: inline-block;
    text-decoration:underline;
    color: yellow;
    cursor: pointer;
  }
  .qa-apr-box .more-content h4{
    font-size: 1rem !important;
  }
  .qa-apr-box .more-content p{
    font-size: 0.8rem;
  }
  .triangle-shadow{
    /* width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-left: 95px solid #2d2d2d;
    border-bottom: 25px solid transparent;
    display: block;
    position: absolute;
    top: 65px;
    transform: rotate(-110deg);
    left: -40px; */
    background-image: url('./../../assets/svgs/Approach/triangle-shadow.svg');
    width: 128px;
    height: 128px;
    transform: rotate(175deg);
    position: absolute;
    top: 43px;
    left: -66px;
  }
  .lavender .arrow{
    /* border: solid #b19cd9;
    background-color: #b19cd9; */
    border: solid #110bc0;
    background-color: #110bc0;
    border-width: 0 30px 30px 0px;
}
.lavender .approach-text-container{
    /* background-color: #b19cd9; */
    background-color: #110bc0;
}
.lavender{
    color: #05034a;
}

.lightblue .arrow{
    /* border: solid #add8e6;
    background-color: #add8e6;  */
    border: solid#090672;
    background-color: #090672;
    border-width: 0 30px 30px 0px;
}
.lightblue .approach-text-container{
    /* background-color: #add8e6; */
    background-color: #090672;
}
.lightblue{
    color: #05034a;
}

.lightpink .arrow{
    /* border: solid #f0a6ca;
    background-color: #f0a6ca; */ 
    border: solid #05034a;
    background-color: #05034a;
    border-width: 0 30px 30px 0px;
}
.lightpink .approach-text-container{
    /* background-color: #f0a6ca; */
    background-color: #05034a;
}
.lightpink{
    color: #05034a;
}
.approach .phase{
    font-size: 1rem !important;
    margin-bottom: 0.5rem;
}
.approach .phase-desc{
  font-size: 0.85rem !important;
}
.step-track{
    position: absolute;
    top: 30px;
    left: 15px;
    font-weight: bold;
}
@media screen and (min-width: 576px) {

.approach .phase-desc{
  font-size: 0.95rem !important;
}
.approach .phase{
  font-size: 1.2rem !important;
  margin-bottom: 0.5rem;
}
}
@media screen and (min-width: 768px) {
    .approach-text-container{
        width:calc(80% - 45px);
    }
    /* .approach .phase{
      font-size: 1.2rem !important;
      margin-bottom: 0.5rem;
  } */
  .approach .phase-desc{
    font-size: 1rem !important;
  }
  .approach-text-container{
    padding: 20px 5px 20px 0;
  }
}
@media screen and (min-width: 992px) {
  .approach .phase{
    font-size: 1.5rem !important;
}
}


/* jigsaw design */

.jigsaw {
    position: relative;
    -webkit-border-radius: .5em;
    -moz-border-radius: .5em;
    border-radius: .5em;
    /* cursor: pointer; */
    margin-bottom: 10px;
    width: 100%;
  }
  
  /* .jigsaw,
  .jigsaw span {
   background-color: #6d6dff; 
  } */

  .marketing-approach .col-lg-2:nth-child(odd) .jigsaw,
  .marketing-approach .col-lg-2:nth-child(odd) .jigsaw span {
   background-color: #0a068f; 
  }
  .marketing-approach .col-lg-2:nth-child(even) .jigsaw,
  .marketing-approach .col-lg-2:nth-child(even) .jigsaw span {
   background-color: #05034a; 
  }
  
  /* .marketing-approach .col-md-2:nth-child(even) .jigsaw .step-num{
    background-color: #6d6dff; 
}
.marketing-approach .col-md-2:nth-child(odd) .jigsaw .step-num{
    background-color: #05034a; 
} */
  
  .jigsaw .text {
    display: block;
    color: #fff;
    font-size: 1.2em;
    text-align: center;
    padding: 2rem 0.5rem;
    position: relative;
    background: transparent !important;
    z-index: 99;
    margin-bottom: 50px;
  }
  
 
  
  .jigsaw .r {
   width: 2em;
    height: 2em;
    position: absolute;
    top: 50%;
    right: -1.5em;
    -webkit-border-radius: 4em;
    -moz-border-radius: 4em;
    border-radius: 4em;
    z-index: 99;
  }
  

  
  .jigsaw .l { 
   width: 1.8em;
    height: 1.8em;
    position: absolute;
    top: 50%;
    left: -.4em;
    -webkit-border-radius: 4em;
    -moz-border-radius: 4em;
    border-radius: 4em;
    background-color: black;
    z-index: 9;
  }
  .jigsaw .b{ 
    width: 2em;
    height: 2em;
    position: absolute;
    bottom: -1.2em;
    left: 40%;
    border-radius: 4em;
    background-color: #02012d !important
}
.jigsaw .t{
    width: 2em;
    height: 2em;
    position: absolute;
    top: -1em;
    left: 40%;
    border-radius: 4em;
   }


.marketing-approach .col-lg-2{
    display: flex;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
}
.step-num{
    text-align: center;
    font-weight: bold;
    padding: 2rem 0;
    border-bottom: 1px solid #5c5a5a;
}
.jigsaw svg{
  width: 50px;
  height: 50px;
  fill: #4f4f56;
  margin-bottom: 25px;
}
.marketing-svg{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}
.jigsaw .phase-desc{
    font-size: 1rem;
    padding: 0 10px;
}
@media screen and (max-width: 767px) {
    .jigsaw .t, .jigsaw .b, .jigsaw .l, .jigsaw .r{
        display: none;
    }
}
