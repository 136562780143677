.contact-us-form{
    /* position: absolute;
    top: 20%;
    z-index: 9; */
    /* width: 55%; */
    /* left: 20%; */
}
.contact-us-conatiner{
    position: relative;
}

.contact-us-btn {
    margin-top: 10px;
    width: 150px !important;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    height: 55px;
    text-align:center;
    border: none;
    background-size: 300% 100%;

    border-radius: 50px;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    background-color: #FFD700 !important;
    color: #001F4D !important;; 
      box-shadow: 0 5px 15px rgba(242, 97, 103, .4);
      margin-left: auto;
      margin-right: auto;
      display: block;
}
.contact-us-btn:focus {
    outline: none;
}
.contact-us-form input, .contact-us-form textarea{
    background: transparent;
    border: 0;
    border-bottom: 1px solid #666464;
    padding: 5px 0;
    color: #FFF;
    width: 100%;
}
.contact-us-form input:focus, .contact-us-form textarea:focus  {
    outline: none;
    border-bottom: 1px solid #FFF;
}
.contact-us-form label{
    color: #898787
}

/* .contact-us-text{
    font-size: 3.5rem;
} */
/* .contact-us-conatiner .img-container img {
    max-width: 95%;
} */
@media screen and (min-width: 768px) {
    .contact-us-conatiner .img-container img{
        max-width: 95%;
    }
    .contact-us-form{
        top: 25%;
    }
    .contact-us-btn {
        margin-top: 35px;
        margin-left: auto;
        margin-right: initial;
    }
 }
 @media screen and (min-width: 389px) {
    .contact-us-btn {
        margin-top: 20px;
    }
 }
 .contact-us-form label.required::after{
    content: " * ";
    color: #f23737;
 }

 #contactus .img-container{
    margin-bottom: 19%;
 }
 #contactus .img-container img{
 margin-left: auto;
    display: block;
    min-width: 90%;
 }