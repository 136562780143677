.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@property --progress {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

.timer-wrapper {
   display: none; 
  border-radius: 50%;
  background: conic-gradient(
    from 0deg,
    #779933 calc(var(--progress) * 1%),
    transparent 0
  );
  position: fixed;
    bottom: 50px;
    right: 20px;
    width: 30px;
    height: 30px;
    border: 1px solid #779933;
}
.timer-wrapper svg{
    transform: rotate(270deg);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 0;
}

@supports (animation-timeline: scroll()) {

  :is(.timer-wrapper) {
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-timeline: scroll();
  }

  .timer-wrapper {
    animation-name: progress;
    animation-range: 0 var(--plunge-start),
      var(--plunge-start) var(--plunge-end);
  }

 
  @keyframes progress {
    to {
      --progress: 100;
    }
  }

 /* @keyframes turn-upright {
    from {
      rotate: -10deg;
    }
    to {
      rotate: 0;
    }
  }  */

}
.fixed-contact-info{
  position: fixed;
  top:35%;
  right: 0;
  box-shadow: 0px 0px 5px 0px #857b7b;
  padding: 10px;
  border-radius: 10px 0px 0 10px;
}
.fixed-contact-info:hover{
  background: #02012d;
}
.fixed-contact-info.top-calc{
  top:calc(35% + 56px);
}
.fixed-contact-info.top-calc-email{
  top:calc(35% + 112px);
}
.contact-info-det{
  display: none;
  padding-left: 15px;
  font-size: 12px;
}
.fixed-contact-info:hover .contact-info-det{
  display: block;
}
.fixed-contact-info p{
  margin-bottom: 0;
}
@media screen and (min-width:768px){
  .fixed-contact-info{
    right: 20px;
  }
}