.card-icon{
    width: 50px;
    fill:#605dba;
    margin: 0 auto;
}
.card-container{
    text-align: center;
    padding: 30px 0;
}
/* .card-container:hover{
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    box-shadow: 0px 0px 7px 4px #f0f0f8;
} */
.service-card-img img{
    width: 100%;

}
.service-card-img{
    border: 5px solid #FFF;
    margin-bottom: 40px;
}