/* .svg-container{
  position: relative;
  transform: skewY(15deg);
  display: inline-block;
}
.svg-container.transform-right{
  transform: skewY(-10deg);
}
.text-on-svg{
  position: absolute;
  top: 37%;
  display: inline-block;
  width: 80%;
  left: 12%;
  font-size: 21px;
} */
.web-svg-container svg{
  width: 50px;
    height: 50px;
}
.web-svg-container{
  /* display: inline-block; */
  margin-bottom: 15px;
}
@keyframes moveText {
  from { left: 0; }
  to { left: 100%; }
}

.moving-text {
  position: relative;
  animation: moveText 20s infinite;
}
.search-bar{
  position: relative;
  display: inline-block;
}
.search-bar .moving-text{
  position: absolute;
  top: 180px;
  left: 75px;
  width: 290px;
  color: black;
}
.web-services h4{
  color: #add8e6;
}
/* .web-services .row:nth-child(even){
  background-color:#05034a
} */
.web-services > .row{
  padding: 1rem 0;
  position: relative;
}
/* .web-services > .row::after{
  content: ' ';
  border: 1px solid #393939;
    width: 80%;
    left: 10%;
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
} */
.mt-10vw{
  margin-top: 10vw;
}
.web-svg{
  border: 1px solid lightblue;
    border-radius: 50%;
    padding: 15px;
    background: linear-gradient(45deg, lightblue, transparent);
    display: inline-block;
}
.web-svg svg{
  transition: all 0.5s;
}
.web-services > .row:hover .web-svg svg{
  transform: rotateY(180deg);

}