.img-container img{
    max-width: 100%;
    z-index: 9;
    position: relative;
}
@media screen and (min-width: 768px) {
    .img-container img{
        max-width: 80%;
        z-index: 9;
        position: relative;
    }
    .banner-text-container{
        margin-left: -5%;
    }
 }
.pos-relative{
    position: relative;
    
}
.pos-relative figure img{
    position: absolute;
    max-width: 100%;
    bottom: 0;
    left: 0;
}



/* .bubbles{
    border: 1px solid grey;
    border-radius: 50%;
    display: inline-block;
    padding: 40px 10px;
    background: #D54B5E;
    width: 100px;
    height: 100px;
    text-align: center;
} */
.banner-overlay{
    background-image: url('./../../assets/images/bg-overlay3.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 0;
    animation: bannerOverlay 2s ;
}
@keyframes bannerOverlay {
    from{background-position: -50% -50%;}
    to{background-position: 0 0;}
}

/* .banner-container{
    background: url('./../../assets/images//banner-bg.png');
    
    background-size: cover;
    background-position: 50% 50%;
    height: 80vh;
} */
.banner-text-container{
    margin-left: 0;
}
.rocket-container{
    
    width: 50%;
    right: 20%;
    position: absolute;
    top: 82%;
}
@media screen and (min-width:992px){
    .rocket-container{
        width: 30%;
        right: 0;
        top: 15%;
    }
}
.rocket-container img{
    max-width: 25%;
    position: absolute;
    top: 9px;
    left: 35%;
    animation-name: rocketMove;
    animation-duration:0.8s;
    animation-iteration-count: infinite;
}
  
  @keyframes rocketMove {
    0% {left: 35%;}
    25% {left: 35.6%;}
    50% {left: 35%;}
    75% {left: 35.6%;}
    100% {left: 35%;}
  }

   .animation-svg{
    position: absolute;
   }
   .qa-icon{
    
    right: 18%;
    bottom: 44%;
    fill: #f7e054;
    z-index: -1;
    transform: skewY(0deg);
    animation: qaIconAnim 3s infinite ease-in-out;
   }
   @keyframes qaIconAnim {
    from{ transform: skew(0deg);    fill: #f7e054;}
    to{transform: skewY(10deg);    fill: transparent;}
   }
   .empty-div{
    visibility: hidden;
   }




   .waves {
    position:relative;
    width: 100%;
    height:15vh;
    margin-bottom:-7px; /*Fix for safari gap*/
    min-height:100px;
    max-height:150px;
  }
  
  .content {
    position:relative;
    height:20vh;
    text-align:center;
    background-color: white;
  }
  
  /* Animation */
  
  .parallax > use {
    animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
  @keyframes move-forever {
    0% {
     transform: translate3d(-90px,0,0);
    }
    100% { 
      transform: translate3d(85px,0,0);
    }
  }
  /*Shrinking for mobile*/
  @media (max-width: 768px) {
    .waves {
      height:40px;
      min-height:40px;
    }
    .content {
      height:30vh;
    }
    h1 {
      font-size:24px;
    }
  }