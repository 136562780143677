.Performance-blog-container ol li{
    margin-bottom: 10px;
}
.Performance-blog-container .bi{
    font-size: 27px;
    color: #6d6dff;
}
.ml-42px{
 margin-left: 42px;
}
.Performance-blog-container .subpage-header{
    padding: 6% 0 3% 0;
}
#htmlcodeLazy .code-snnippet-container{
    height: 120px;
}
#nexarahelps h4{
    margin-top: 1.5rem;
}