
footer{
    padding: 0 0 20px 0;
    background: black;
}
.footer-logo{
    text-shadow: 1px 1px 3px #7d71c3;
     text-align: center; 
     margin-top: 3rem;
}
@media screen and (min-width:576px){
    .footer-logo{
         text-align: left; 
         
    }
}
.footer-logo .img-container img {
    max-width: 120px;
}
.social-icon{
    margin-right: 20px;
}

.social-icon i:hover{
color: #6d6dff;
top: -5px;
}
.social-icon i{
    position: relative;
    top:0;
    transition: top 0.4s;
}
footer .nav-link{
    font-size: 1rem;
}
footer .nav-link:hover{
    color: #FFD700;
}
footer .nav-item{
    margin-left: 0;
}