@keyframes fadeIn {
    from {top: 160%;}
    to {top: 100%;}
 }
 
 .fadeIn {
    top: 100%;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    animation-duration: 0.25s;
    left: 0;
   
 }
 /* .dropdown:hover .fadeIn{
    display: block;
 } */
 .navbar-collapse{
    justify-content: right;
 }

 @media screen and (min-width: 768px) {
    .dropdown:hover .fadeIn{
        display: block;
     }
     .fixedNav .dropdown .fadeIn{
      display: block;
   }
 }
 /* nav{
    padding: 25px 5% !important;
 } */
 nav .navbar-nav{
    font-size: 19px;
 }
 .navbar-brand{
    font-size: 30px;
    color: #FFF;
 }
 /* .navbar-brand:hover{
    text-shadow: 1px 1px 3px #7d71c3;
 } */
 .nav-item{
   margin-left: 5rem;
 }
 .navbar .navbar-nav .nav-item .nav-link{
    color: #F5F5DC ;
 }
 .navbar .navbar-nav .nav-item .nav-link:hover, .navbar .navbar-nav .nav-item .nav-link.active, 
 .fixedNav .navbar-nav .nav-item .nav-link.active{
    color: #FFD700;
 }
 /* .dropdown-menu .dropdown-item:hover{
    background: linear-gradient(45deg, #f0f0f8, transparent);
    border-bottom: 1px solid #eee;
    color: #7d71c3;
 } */
 .dropdown-menu{
    border: 0;
    background: linear-gradient(90deg, #02012d, #6d6dff);
    box-shadow: 0px 0px 5px 0px #a19d9d;
 }
 .dropdown-item{
    padding-top: 10px;
    padding-bottom: 10px;
    color: #FFF;
 }
 .logo-top-design{
    width: 105px;
    height: 1px;
    background: #605dba;
    position: absolute;
    top: 14px;
    left: 0;
 }
 .logo-top-design > div{
    position: absolute;
    top: -10px;
    font-size: 12px;
    background: #f0f0f8;
    left: 47%;
    margin: auto;
    font-family: cursive;
    color: #605dba;
    padding: 0 6px;
 }

 .nexara-brand{
    letter-spacing: -14px;
    -webkit-text-stroke: 1px #000000;
    font-size: 36px;
    display: inline-block;
    margin-right: 10px;
 }
 .nexara-brand .nexara-x{
 color: #f82586;
 z-index: 9;
 font-size: 50px;
 }
 .nexara-brand span{
    z-index: 99;
    position: relative;
    }
 .logo-name{
    display: inline-block;
    padding-left: 15px;
    font-size: 18px;
    vertical-align: text-top;
    letter-spacing: 1.5px;
    color: darkgray;
    text-shadow: 1px 2px 1px BLACK;
 }
 .nexara-brand span:hover{
    text-shadow: 1px 1px 3px #7d71c3;
 }
 .navbar-brand:focus .nexara-brand, .navbar-brand:hover .nexara-brand{
    color:#000;
}
.navbar-brand:focus .logo-name, .navbar-brand:hover .logo-name{
    color:#FFF;
}
.nexara-brand img{
   max-width: 120px;
}
/* .navbar-light{
   background: transparent;
   padding: 0 30px 0 10px;
} */

@media screen and (max-width:767px) {
   .navbar{
      padding-left: calc(1.5rem * 0.5) !important;
      padding-right: calc(1.5rem * 0.5) !important;
    }
}
.navbar-toggler-icon{
   background-image: none;
}
.nav-link{
   cursor: pointer;
}
.navbar .navbar-toggler{
   font-size: 40px;
}

@media screen and (max-width: 991px){
   .navbar .navbar-toggler + .navbar-collapse{
      position: fixed;
      height: 100vh;
      top: 0;
      bottom: 0;
      width: 100%;
      background: #02012d;
      left: 0;
      z-index: 9999;
      padding-top: 10vh;
   }

    .dropdown-menu {
      background: #02012d;
      width: 75%;
  }
  .dropdown-item.active, .dropdown-item:active {
   color: yellow;
   background-color: transparent;
}
.fixedNav .dropdown-menu.show{
   position: relative !important;
    transform: none !important;
}
   
}

.closeIcon{
   position: absolute;
    top: 0;
    right: 0;
    background: black;
    box-shadow: 0px 0px 3px 1px #FFF;
    padding: 15px;
    border-radius: 0 0 0 50%;
    cursor: pointer;
    display: block;
}
@media screen and (min-width: 992px) {
 .closeIcon{
   display: none;
} 
.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item.active{
   background: transparent;
   border-bottom: 1px solid transparent;
   color: yellow;
} 
.nav-item{
   margin-left: 3rem;
 }
}
@media screen and (min-width: 1141px) {
   .nav-item{
      margin-left: 5rem;
    }
}