.marketing-header{
    -webkit-text-stroke: 2px grey;
    color: transparent;
    font-size: 44px;
}
.nexara-quote
{
  background-image: linear-gradient(
    -225deg,
    #FFF 0%,
    #9a78be 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.text-stroke-img{
    -webkit-text-stroke: 1px rgba(245, 245, 220, 0.6);
    color: transparent;
    font-size: 4rem;
    background: url("./../../assets/svgs/Marketing/solution.svg") center;
    background-clip: text;
    text-transform: uppercase;
    background-size: contain;
    line-height: 5rem;
    word-wrap: break-word;
    animation: marketingTxt 2s infinite ease;
}
@keyframes marketingTxt {
  from{background-position: 20%;}
  to{background-position: 0%;}
}
@media screen and (min-width:768px) and (max-width:992px) {
  .text-stroke-img{
    font-size: 3rem;
  }
}
.marketing-services > .container-fluid:nth-child(even){
    background-color:#05034a
  }
  .marketing-services > .container-fluid{
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .marketing-services > .container-fluid:last-child{
    padding-bottom: 0;
  }
  .marketing-services .question-icon, .marketing-services .question-text{
    margin: 1rem 0 0 0;
  }
  .marketing-services .qa-headers{
    margin-bottom: 2rem;
  }
  .marketing-services .solution-text svg .st0{
    stroke: #84ef63;
    stroke-width: 5;
  }
  .marketing-services > .container-fluid:nth-child(even) > .row{
    flex-direction: column-reverse;
  }
  .marketing-services > .container-fluid .text-stroke-img{
    text-align: center;
  }
  @media screen and (min-width:768px){
    .marketing-services > .container-fluid:nth-child(even) > .row{
      flex-direction: initial;
    }
    .marketing-services .solution-text svg{
      width: 40px;
    }
    /* .marketing-services > .container-fluid .text-stroke-img.text-right{
      text-align: right;
    }
    .marketing-services > .container-fluid .text-stroke-img{
      text-align: left;
    } */
  }

