/* .specilization-content{
    display: none;
   
} */

.specilization-content.default{
    display: block;
}



.specilization-content{
    display: none;
    /* position: absolute;
    right: 10%;
    top:25%;
    width: 45%; */
        position: absolute;
    left: 40%;
    top:0;
    /* animation: hovermove 1s 0s 1; */
    
}

.specilization-content .img-container{
    /* position: absolute;
    left: 37%; */
    position: relative;
    width: 30%;
    float: left;
    animation: moveImg 2s;
}

@keyframes hovermove {
    from{left:45%;}
    to{left:40%;}
}
@keyframes moveImg {
    from{margin-top: 15%;opacity: 0.2;}
    to{margin-top: 0;opacity: 1;}
}

.specilization-content.transform45{
    transform: rotate(45deg);
}
.specialization-container{
    position: relative;
    /* background-color: rgba(64, 66, 105,1); */
    /* background: black; */
    /* background-image: url('./../../assets/svgs/slant-line.svg');
    background-attachment: fixed;
    background-size: cover; */
}
.specialization .specilization-header{
    text-decoration: none;
    font-size: 25px;
    color: #b19cd9; 
    padding: 2rem 0;
    display: block;
    margin: 0;
    transition: margin 0.9s;
    display: inline-block;
}
.specilization-header:hover{
margin: 0 0 0 20px;
}
.inside-text{
    top: 37px;
    position: absolute;
    color: #000;
    left: 17px;
}
.expand-service{
    display: none;
    margin:0 0 0 20px;;
    animation: hovermovetop 1s 0s 1;
    color: #ac7465;
}
.specialization:hover .expand-service{
    display: block;
    /* margin:0 0 0 40px;; */
}
.expand-service a{
    text-decoration: none;
    /* color: #ac7465; */
}
@keyframes hovermovetop {
    from{margin:  -10px 0 0 0px; }
    to{margin: 0 0 0 20px;}
}
/* .overlap-svg{
    margin-left: -100px;
    margin-top: 180px;
} */
 /* .performance img{
    max-width: 70%;
}  */
.specilization-content .img-container img{
    max-width: 150px;
    display: none;
}
@media screen and (min-width: 768px) {
    .specilization-content .img-container img{
        max-width: 250px;
        margin-top: 15%;
        display: block;
    }
    .specilization-header:hover + .specilization-content{
        display: block;
        margin:0 0 0 20px;
      
    }
   
 }
 @media screen and (max-width: 650px) {
 .specilization-content.default{
    display: none !important;
 }
}

 .banner-solutions .bs{
    margin-top: 10px;
   }
   /* .banner-solutions .bs:nth-child(2){
    margin-left: 10%;
   }
   .banner-solutions .bs:nth-child(3){
     margin-left: 20%;
    }
    .banner-solutions .bs:nth-child(4){
     margin-left: 30%;
    } */
    .banner-solutions{
     padding-top: 10%;
     padding-bottom: 10%;
     padding-left:30%;
     padding-right: 10%;
 }
    
 
    .display-container img{
     max-width: 80%;
     /* transform: skew(10deg, 10deg); */
     
    }
    .display-container{
    position: relative;
    width: 70%;
    float: left;
    animation: moveTxt 2s;
    }
    @keyframes moveTxt {
        from{margin-top: -15%; opacity: 0.2;}
        to{margin-top: 0; opacity: 1;}
    }
 .display-container.web{
    /* background: url('./../../assets/images/laptop.png');
    background-size: cover;
    background-repeat: no-repeat; */
 } 
 .specialization-list{
    position: relative;
    margin: 4rem 0 0 0;
 }
 /* .specilization-content img{
    position: absolute;
    opacity: 0.3;
    right: 20%;
    top:0;
   
 } */
 .specilization-header h4{
    font-weight: bold;
 }